import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Image from 'common/components/Image';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import NavbarWrapper, { MenuArea, MobileMenu, Social } from './navbar.style';
import LogoImage from 'common/assets/image/appModern/logoCryogeniteTransparent.png';
import LogoImageAlt from 'common/assets/image/appModern/logoCryogeniteTransparent.png';


const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
      appMinimalJson {
        FooterData {
          social {
            link
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  
  const { navMenu } = data.appModernJson.navbar;
  const { social } = data.appMinimalJson.FooterData;
  const [state, setState] = useState({
    mobileMenu: false,
  });


  const toggleHandler = (type) => {
    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  const scrollItems = [];

  navMenu.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <MenuArea>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          {/* end of main menu */}

          <Logo
          href="/"
          logoSrc={LogoImage}
          title="Cryogénite"
          className="main-logo"
        />
        <Logo
          href="/"
          logoSrc={LogoImageAlt}
          title="Cryogénite"
          className="logo-alt"
        />

          <Link to="/contact">
            <Button className="trail" title="Demander un devis" />
          </Link>

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
        <Social>
          {social.map(({ link, icon }, index) => (
            <Link className="social-link" to={link} key={`footer-social-key-${index}`}>
              <Image src={icon.publicURL} alt="social image" />
            </Link>
          ))}
        </Social>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <ul>
            {navMenu.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <Link
                  to={menu.path}
                  onClick={handleRemoveMenu}
                >
                  {menu.label}
                </Link>
              </li>
            ))}
          </ul>
          <Link to="/contact">
            <Button title="Demander un devis" />
          </Link>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
