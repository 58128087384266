import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const NavbarWrapper = styled.nav`
  width: 100%;
  padding: 25px 0 26px;
  background: linear-gradient(#380036, #301F4C);

  border-bottom: 1px solid rgba(255, 255, 255, 0.102);
  position: fixed;
  z-index: 9999;
  transition: all 0.3s ease;
  @media only screen and (max-width: 1366px) {
    padding: 20px 0 21px;
  }
  > div.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 28em;
    @media only screen and (max-width: 991px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 480px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 1366px) {
      margin-left: 0em;
    }
  }
    .main-logo {
      position: relative;
      width: 50%;
      img {
        position: absolute;
        width: 190px;
        left: 100px;
        top: -50px;
        height: auto;
        @media only screen and (max-width: 991px) {
          width: 175px;
          left: 243px;
          top: -70px;
        }
        @media only screen and (max-width: 480px) {
          width: 100px;
          left: 126px;
          top: -40px;
        }
      }
    }
    .logo-alt {
      height: auto;
      display: none;
      position: relative;
      width: 50%;
      img {
        position: absolute;
        width: 190px;
        left: 100px;
        top: -55px;
        height: auto;
        @media only screen and (max-width: 991px) {
          width: 175px;
          left: 243px;
          top: -70px;
        }
        @media only screen and (max-width: 480px) {
          width: 100px;
          left: 126px;
          top: -38px;
        }
      }
    }
  }
  ul {
    li {
      a {
        color: ${themeGet('colors.menu', '#fff')};
        font-size: 16px;
        font-weight: 400;
        transition: all 0.3s ease;
        &:hover {
          font-weight: 700;
        }
      }
      &.is-current {
        a {
          font-weight: 700;
        }
      }
    }
  }
  }
`;

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .menu {
    display: flex;
    align-items: center;
    margin-right: 11px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    @media only screen and (max-width: 1366px) {
      margin-right: 13px;
    }
    @media only screen and (max-width: 991px) {
      display: none;
    }
    li {
      margin: 0 19px;
      @media only screen and (max-width: 1366px) {
        margin: 0 17px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.active {
    .menu {
      opacity: 0;
      visibility: hidden;
    }
  }
  .reusecore__button {
    border-radius: 5px;
    font-weight: 500;
    text-transform: inherit;
    padding-left: 13px;
    padding-right: 13px;
    min-height: 42px;

    &.text {
      padding: 0;
      margin-right: 28px;
      cursor: pointer;
      .btn-icon {
        svg {
          width: 22px;
          height: auto;
          stroke: ${themeGet('colors.menu', '0D233E')};
          @media only screen and (max-width: 991px) {
            width: 24px;
          }
        }
      }
      @media only screen and (max-width: 1366px) {
        margin-right: 20px;
      }
      @media only screen and (max-width: 991px) {
        margin-right: 0;
      }
    }
    &.trail {
      &:hover {
        box-shadow: #d1397c 0px 12px 24px -10px;
      }
      @media only screen and (max-width: 991px) {
        display: none;
      }
    }
    &.menubar {
      display: none;
      @media only screen and (max-width: 991px) {
        display: inline-flex;
        padding: 0;
        justify-content: flex-end;
        min-width: 35px;
        color: #fff;
        svg {
          width: 27px;
          height: auto;
        }
      }
    }
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    padding: 27px 0 40px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 82px;
    flex-direction: column;
    background: linear-gradient(#311948, #0CBABA);
    transition: all 0.3s ease;
    &.active {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 3px 12px
        ${themeGet('colors.shadow', 'rgba(38, 78, 118, 0.1)')};
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul {
      padding-bottom: 20px;
      li {
        a {
          display: block;
          padding: 13px 0;
          border-radius: 5px;
          transition: all 0.3s ease;
        }
        &:hover {
          a {
            padding: 13px 15px;
            color: ${themeGet('colors.primary')};
          }
        }
      }
    }
    .reusecore__button {
      width: 100%;
      border-radius: 5px;
      @media only screen and (max-width: 480px) {
        margin-top: 20px;
      }
    }
  }
`;

export const Social = styled.div`
  display: flex;
  width: 25%;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  a {
    margin-left: 15px;
  }
  img {
    width: 40px;
  }
  img:hover {
    transition: all 0.3s ease;
    width: 50px;
  }
  @media only screen and (max-width: 991px) {
    position: absolute;
    justify-content: flex-start;
    img {
      width: 25px;
    }
    img:hover {
      transition: all 0.3s ease;
      width: 10px;
    }
  }
`;

export default NavbarWrapper;
