import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Container from 'common/components/UI/Container';
import FooterArea, {
  Left,
  Menu,
  CopyText,
} from './footer.style';

const Footer = () => {
  const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        FooterData {
          menu {
            link
            label
          }
          logo {
            publicURL
          }
          social {
            link
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { menu } = Data.appMinimalJson.FooterData;
  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea>
      <Container className="Container">
          <CopyText>
            Copyright © {year}
          </CopyText>
      </Container>
    </FooterArea>
  );
};

export default Footer;
